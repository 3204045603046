<template>
    <div>
        <div class="card col-lg-12 col-md-12 col-sm-12" style="min-height: 20em; padding-bottom: 5px">
            
            <b-button v-b-tooltip.top="$t('data.role_details_retour')" @click="() =>{ this.$router.push({name: 'roles'}) }" class="card icon-list-table d-flex justify-content-center align-items-center"><i class="fa fa-arrow-left fa-2x" style="color:white"></i></b-button>
            <div ><h4 style="margin-left: 5em" class="mt-2 text-capitalize font-weight-bold">Liste des Privilèges</h4></div>        
            <div class="d-flex" style="width : 70%; align-items: flex-start">
            </div> 
            <b-container>
                <b-overlay :show="showOverlay" rounded="sm">
                    <div>
                        <h4 class="text-muted">Libelle</h4>
                        <h5 class="text-uppercase font-weight-bold">user</h5>
                        <hr>
                        <v-select label="codePrivilege" multiple :options="privileges.list" v-model="privileges.selected" class="w-100" :reduce="privilege => privilege.idPrivilege">
                            <template #option="{ codePrivilege}">
                                {{ codePrivilege }}
                            </template>
                        </v-select>
                        <hr>
                    </div>
                    <div class="float-right mt-2">
                        <b-button :disabled="submitted" @click.prevent="save" variant="danger"><b-spinner class="m-2" small v-if="submitted" style="margin-left: 5px;"></b-spinner>Enregistrer</b-button>
                    </div>
                </b-overlay>
            </b-container>
        </div>
    </div>
</template>
<script>

export default {
    name:'details-role',
    components:{
        
    },
      data: () => ({
        submitted:false,
        showOverlay: true,
        privileges: {list: [], selected: []},
      }),
      methods: {
          save(){    
            this.submitted=true;    
            axios.put('entreprises/roles/'+this.editRole.idRole, this.role).then(response => {
            this.submitted = false
              App.alertSuccess(response.message)
            }).catch(error => {
                this.submitted = false
                App.notifyError(error.message)
            })
          },
          getPrivileges() {
            axios.get('constants/privileges').then(response => response.result || []).then(privileges => {
                
                this.privileges.list = privileges
                console.log('liste des prvilèges :',this.privileges.list);
                this.privileges.selected = privileges.map(elt => elt.idPrivilege)
                this.showOverlay=false
                console.log('privileges',this.privileges.list);
            })
        },
  
      },
      mounted(){
          this.getPrivileges();
      },
}
</script>
<style scoped>
    .icon-list-table{
        width: 4em;
         height: 4em; 
         background: #f5365c;;
         position: absolute;
         top:-5%;
    }
    .text-list{
        position: absolute;
        top: 5px;
        left: 8%;
    }
    .actions-buttons-group{
        position: absolute;
        top: 5em;
        left: 8%
    }

</style>